<template>
  <client-only>
    <EpiEditButton
      v-if="showEditButton && page && page.Content && page.Content.items"
      :page-id="page.Content.items[0].ContentLink?.Id.toString()"
      class="mobileOnly:!hidden"
    />
  </client-only>
  <div class="default-vue">
    <div
          :class="{
            'min-h-screen-default': !isEditMode,
            '!h-full !min-h-24 !max-h-[20000px]': isEditMode,
            'bg-background': true,
          }"
        >
        <main class="main">
            <slot />
          </main>
        </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalContentStore } from '~/store/globalContent';
import { usePageContentStore } from '~/store/pageContent';
const pageStore = usePageContentStore();
const { page, pageTypeString } = storeToRefs(pageStore);
import { useUiStore } from '~/store/ui';
import EpiEditButton from '~/components/globals/EpiEditButton.vue';

const config = useGlobalContentStore().config;
const uiStore = useUiStore();

// Detects mobile
// const updateWidth = () => {
//   uiStore.setIsMobile(window.innerWidth <= 767);
//   uiStore.setIsTablet(window.innerWidth > 640 && window.innerWidth <= 1024);
// };

onMounted(() => {
  setWindowHeight();
  window.addEventListener('resize', setWindowHeight);
});

// Creates a css variable for 100vh that works on ios safari
const setWindowHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const isEditOrPreviewMode = computed(() => {
  const route = useRoute();
  return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
});

onUnmounted(() => {
  window.removeEventListener('resize', setWindowHeight);
  window.removeEventListener('resize', updateWidth);
  document.body.classList.remove('noScroll');
});

const isEditMode = computed(() => {
  const route = useRoute();
  return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
});

const showEditButton = computed(() => {
  if (isEditOrPreviewMode.value) {
    return false;
  }
  const { showEditButton } = config;
  return showEditButton;
});

</script>

<style>
.main {
  @apply min-h-screen flex flex-col;
}
.min-h-screen-default {
  min-height: calc(100vh - 200px);
}
</style>
